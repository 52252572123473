<template>
  <div>
    <sampling-stations />
  </div>
</template>

<script>
import SamplingStations from '@/components/Devices/samplingStations/SamplingStations.vue'

export default {
  components: { SamplingStations },
  name: 'SamplingStation',
}
</script>
