<template>
  <div class="mx-5 mx-md-16 px-md-16">
    <span class="d-none">{{ updateList }}</span>

    <v-data-table
      :headers="headers"
      :items="samplingStations.items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :search="search"
      hide-default-footer
      class="elevation-1 rounded-lg"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="editSamplingStations(item)"
          ><v-icon small color="orange">fa-edit</v-icon></v-btn
        >
        <v-btn icon @click="deleteSamplingStations(item)"
          ><v-icon small color="red">fa-trash</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <div class="pt-2 d-flex flex-column flex-md-row align-center justify-end">
      <span class="font-quicksand">Filas por páginas: </span>
      <span style="width: 50px" class="mx-md-2">
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPages"
          class="font-quicksand"
        ></v-select>
      </span>
      <span class="font-quicksand"
        >1-{{ itemsPerPage }} de {{ samplingStations.totalItems }}
      </span>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="pageVisible"
        color="red"
        class="font-work-sans"
      ></v-pagination>
    </div>

    <dialog-sampling-station
      :samplingStation="samplingStation"
      :del="del"
      :dialog="dialog"
      @dismiss="
        () => {
          dialog = false
          loadData()
        }
      "
    />

    <form-dialog
      :samplingStation="samplingStation"
      :edit="edit"
      :dialog="dialog2"
      @dismiss="
        () => {
          dialog2 = false
          loadData()
        }
      "
    />
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import DialogSamplingStation from './Dialog.vue'
import FormDialog from './FormDialog.vue'
const SamplingStationRepository = Repository.get('samplingStations')

export default {
  components: { DialogSamplingStation, FormDialog },
  name: 'ActiveSamplingStations',
  props: ['search', 'newItem'],
  data: () => ({
    samplingStations: {},
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Número',
        align: 'center',
        class:
          'primary white--text rounded-tl-lg font-weight-bold text-subtitle-2',
        value: 'number',
      },
      {
        text: 'Descripción',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'description',
      },
      {
        text: 'Opciones',
        align: 'center',
        class:
          'primary white--text rounded-tr-lg font-weight-bold text-subtitle-2',
        value: 'actions',
      },
    ],
    itemsPerPages: [7, 10, 20, 30, 50, 100],
    del: true,
    edit: true,
    samplingStation: null,
    dialog: false,
    dialog2: false,
  }),
  computed: {
    updateList: async function () {
      if (this.newItem) {
        await this.loadData()
      }
    },
  },
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await SamplingStationRepository.get()
        this.samplingStations = res.data.data
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    deleteSamplingStations: function (item) {
      this.samplingStation = item
      this.dialog = true
    },
    editSamplingStations: function (item) {
      this.samplingStation = Object.assign({}, item)
      this.dialog2 = true
    },
  },
}
</script>
