<template>
  <div class="mx-5 mx-md-16 px-md-16">
    <v-data-table
      :headers="headers"
      :items="samplingStations.items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :search="search"
      hide-default-footer
      class="elevation-1 rounded-lg"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="activeSamplingStation(item)"
          ><v-icon small color="success">fa-sync-alt</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <div class="pt-2 d-flex flex-column flex-md-row align-center justify-end">
      <span class="font-quicksand">Filas por páginas: </span>
      <span style="width: 50px" class="mx-md-2">
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPages"
          class="font-quicksand"
        ></v-select>
      </span>
      <span class="font-quicksand"
        >1-{{ itemsPerPage }} de {{ samplingStations.totalItems }}</span
      >
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="pageVisible"
        color="red"
        class="font-work-sans"
      ></v-pagination>
    </div>

    <dialog-samplingStation
      :samplingStation="samplingStation"
      :del="del"
      :dialog="dialog"
      @dismiss="
        () => {
          dialog = false
          loadData()
        }
      "
    />
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
const SamplingStationRepository = Repository.get('samplingStations')
import DialogSamplingStation from './Dialog.vue'

export default {
  components: { DialogSamplingStation },
  name: 'InactiveSamplingStations',
  props: ['search'],
  data: () => ({
    samplingStations: {},
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Número',
        align: 'center',
        class:
          'primary white--text rounded-tl-lg font-weight-bold text-subtitle-2',
        value: 'number',
      },
      {
        text: 'Descripción',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'description',
      },
      {
        text: 'Opciones',
        align: 'center',
        class:
          'primary white--text rounded-tr-lg font-weight-bold text-subtitle-2',
        value: 'actions',
      },
    ],
    itemsPerPages: [7, 10, 20, 30, 50, 100],
    del: false,
    samplingStation: null,
    dialog: false,
  }),
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await SamplingStationRepository.getDeleted()
        this.samplingStations = res.data.data
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    activeSamplingStation: function (item) {
      this.samplingStation = item
      this.dialog = true
    },
  },
}
</script>
